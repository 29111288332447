/*
 * QUOTE
 */

.c-quote {

    h4 {
        text-transform: none;
        line-height: 1.6;
        position: relative;

        &::before, &::after {
            display: inline-block;
            vertical-align: top;
            height: 30px;
            line-height: 48px;
            font-size: 45px;
            opacity: .75;
        }

        &:before {
            content: '\201C';
            padding-top: 4px;
            margin-right: 4px;
            margin-left: -8px;
        }

        &:after {
            content: '\201D';
            padding-top: 4px;
            margin-left: 4px;
            margin-right: -8px;
        }
    }

    p {
        display: block;
        width: 100%;
    }
}
