// LOADING

.c-loader-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}
.c-loader-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #000000;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.c-loader-ellipsis div:nth-child(1) {
    left: 6px;
    animation: c-loader1 0.6s infinite;
}
.c-loader-ellipsis div:nth-child(2) {
    left: 6px;
    animation: c-loader2 0.6s infinite;
}
.c-loader-ellipsis div:nth-child(3) {
    left: 26px;
    animation: c-loader2 0.6s infinite;
}
.c-loader-ellipsis div:nth-child(4) {
    left: 45px;
    animation: c-loader3 0.6s infinite;
}
@keyframes c-loader1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes c-loader3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes c-loader2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(19px, 0);
    }
}
