/*
 * BUTTONS
 */

.c-button {
    display: inline-block;
    padding: .75rem 1rem;
    background-color: $light;
    border-radius: $border-radius;
    transition: all .15s ease-in-out;
    font-weight: 500;
    color: $dark;
    text-decoration: none;
    font-size: .9rem;
    text-transform: uppercase;
    text-align: center;
    border-bottom: 2px solid $light;

    @include media-query(md) {
        font-size: 1.125rem;
        padding: .75rem 2rem;
    }

    &:hover {
        border-bottom: 2px solid $primary;
        text-decoration: none;
        color: $dark;
    }
}

.c-button-full {
    width: 100%;
    display: block;
}

.c-button-info {
    border-bottom: none;
    background-color: $info;
    color: $white;

    &:hover {
        border-bottom: none;
        background-color: lighten($info, 5%);
        color: $white;
    }
}

.c-button-success {
    border-bottom: none;
    background-color: $success;
    color: #fff;

    &:hover {
        border-bottom: none;
        background-color: lighten($success, 5%);
        color: $white;
    }
}

.c-button-warning {
    border-bottom: none;
    background-color: $warning;
    color: $white;

    &:hover {
        border-bottom: none;
        background-color: lighten($warning, 5%);
        color: $white;
    }
}

.c-button-error {
    border-bottom: none;
    background-color: $error;
    color: $white;

    &:hover {
        border-bottom: none;
        background-color: lighten($error, 5%);
        color: $white;
    }
}

.c-button-primary {
    border-bottom: none;
    background-color: $primary;
    color: $white;

    &:hover {
        background-color: lighten($primary, 5%);
        border-bottom: none;
        color: $white;
    }
}

.c-button-secondary {
    border-bottom: none;
    background-color: $secondary;
    color: $white;

    &:hover {
        border-bottom: none;
        background-color: lighten($secondary, 5%);
        color: $white;
    }
}

.c-button-tertiary {
    border-bottom: none;
    background-color: $tertiary;
    color: $white;

    &:hover {
        border-bottom: none;
        background-color: lighten($tertiary, 5%);
        color: $white;
    }
}
