/*
 * FOOTER
 */

.c-footer-main {
    width: 100%;
    overflow: hidden;
    background-color: $dark;

    a, p, h5 {
        color: $white;
        line-height: 2em;
    }

    .c-footer-nav{
        margin-top:52px;
    }

    .c-nav-item {
        font-weight: bold;
        text-transform: none;
        position: relative;
        line-height: 1;
        margin-left: .75rem;
        margin-right: .75rem;
        margin-bottom: 1.5rem;
        font-family: $h1-font-family;

        &:before {
            content: "";
            height: 100%;
            width: 1px;
            position: absolute;
            left: -.5rem;
            top: 0;
            display: block;
            background-color: rgba(255,255,255,.5)
        }

        &:after {
            content:"";
            width: 100%;
            height: 2px;
            position: absolute;
            background-color: $primary;
            left: 0;
            bottom: -.4rem;
            opacity: 0;
            transition: opacity .25s;
        }

        &:hover {
            text-decoration: none !important;

            &:after {
                opacity: 1;
            }
        }

        @include first {
            &:before {
                display: none;
            }
        }

        @include last {
            margin-right: 0 !important;
        }
    }

    .c-nav-item-active {

        &:after {
            opacity: 1;
        }
    }
}

.c-footer-logo {
    display: block;
    width: 215px;
    margin-top: -1px;
}

.c-footer-bottom{
    background: rgba(0,0,0,0.9);
    display: block;

    p, a {
        font-family: $MetaHeadlineOT;
        font-size: 0.875025rem;
        text-transform: uppercase;
        color: $white;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
        color: $white;
    }
}
