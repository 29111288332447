//
// Icon Font: icons
//

@include fontFace('icons', '../fonts/icons/icons');

[data-icon]:before { content: attr(data-icon); }
[data-icon-right]:after { content: attr(data-icon-right); }

[data-icon]:before,
.icon-arrow-down:before,
.icon-arrow-left:before,
.icon-arrow-right:before,
.icon-check:before,
.icon-times:before {
    display: inline-block;
    font-family: "icons";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

.icon-arrow-down:before { content: "\f103"; }
.icon-arrow-left:before { content: "\f106"; }
.icon-arrow-right:before { content: "\f102"; }
.icon-check:before { content: "\f105"; }
.icon-times:before { content: "\f107"; }

[data-icon-right]:after,
.icon-right-arrow-down:after,
.icon-right-arrow-left:after,
.icon-right-arrow-right:after,
.icon-right-check:after,
.icon-right-times:after {
    display: inline-block;
    font-family: "icons";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

.icon-right-arrow-down:after { content: "\f103"; }
.icon-right-arrow-left:after { content: "\f106"; }
.icon-right-arrow-right:after { content: "\f102"; }
.icon-right-check:after { content: "\f105"; }
.icon-right-times:after { content: "\f107"; }
