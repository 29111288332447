/*
 * Search
 */

.c-search {

    background-color: $white;

    label {
        line-height: 1;
    }

    .o-grid {
        margin: 0 !important;
    }
}
