/*
 * SLIDER
 */

.c-slider-slick-project {

    .slick-prev, .slick-next {

        &:before {

            color: $dark;
        }
    }

    .slick-dots {

        bottom: -24px;

        li {

            button {

                &:before {

                    color: $dark;
                }
            }
        }
    }
}
