/*
 * NAVIGATION
 */

// Mobile

.c-nav-mobile {
    z-index: 100;
}

.c-nav-mobile-button {
    position: relative;
    z-index: 100;
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-width: 44px;
    min-height: 44px;
    overflow: hidden;
    text-indent: -999rem;
    //border: 1px solid rgba($white, .25);

    &:hover {
        cursor: pointer;
    }
}

.c-nav-mobile-button-line {
    display: block;
    width: 22px;
    height: 2px;
    position: absolute;
    left: 50%;
    margin-left: -11px;
    background-color: #fff;

    &:nth-child(1) {
        margin-top: -1px;
        top: calc(50% - 6px);
        transition: transform .2s ease-out,
        top .2s .2s ease-out;

        .c-nav-mobile > input:checked + .c-nav-mobile-button & {
            top: 50%;
            transform: rotate(45deg);
            transition: top .2s .2s ease-out,
            transform .2s .4s ease-out;
        }
    }

    &:nth-child(2) {
        margin-top: -1px;
        top: 50%;
        transition: all .2s .4s ease-out;

        .c-nav-mobile > input:checked + .c-nav-mobile-button & {
            width: 0;
            opacity: 0;
            transition: all .2s ease-out;
        }
    }

    &:nth-child(3) {
        margin-bottom: -1px;
        bottom: calc(50% - 6px);
        transition: transform .2s ease-out,
        bottom .2s .2s ease-out;

        .c-nav-mobile > input:checked + .c-nav-mobile-button & {
            bottom: 50%;
            transform: rotate(-45deg);
            transition: bottom .2s .2s ease-out,
            transform .2s .4s ease-out;
        }
    }
}

.c-nav-mobile-content {
    overflow: auto;

    // Overlay styled menu
    &--overlay {
        //flex-wrap: wrap;
        //justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        margin-bottom: 0;
        padding: 75px 2rem 2rem 2rem;
        list-style: none;
        background-color: rgba($dark, 1);
        opacity: 0;
        transition: opacity .3s ease-out, top 0s .3s, left 0s .3s;
        pointer-events: none;

        .c-nav-mobile > input:checked + .c-nav-mobile-button + & {
            top: 0;
            left: 0;
            opacity: 1;
            transition: opacity .3s ease-out;

            &:before {
                width: 100%;
                height: 75px;
                content: '';
                position: fixed;
                top: 0;
                background-color: $dark;
            }
        }
    }

    // Pushed right menu
    &--pushed-right {
        display: block;
        position: fixed;
        z-index: 99;
        top: 0;
        right: -320px;
        width: 100%;
        max-width: 248px;
        height: 100%;
        padding: 2rem;
        background-color: $dark;
        transition: right .3s ease-out;

        .c-nav-mobile > input:checked + .c-nav-mobile-button + & {
            right: 0;
            transition: right .3s ease-out;
        }

        li {
            display: block;
            margin-bottom: .5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            font-size: rem(24);
            font-weight: bold;
            color: $white;
            transition: all .2s ease-out;

            &:hover {
                color: $tertiary;
            }
        }
    }
}

.c-nav-mobile-content-inner{
    width: 100%;
}

.c-nav-mobile-content-list {

    width: 100%;

    li {
        list-style-type: none;
        display: block;

        a {
            width: 100%;
            color: $white;
            font-weight: bold;
            text-decoration: none;
            display: inline-block;
            text-align: center;
            text-transform: none;
            font-size: 1em;
            font-family: "Meta OT Bold", Helvetica, sans-serif;
            margin-left: auto;
            margin-right: auto;
            position: relative;

            span {
                position: relative;
                display: inline-block;

                &:after {
                    content: "";
                    width: 100%;
                    height: 2px;
                    position: absolute;
                    background-color: $primary;
                    left: 0;
                    bottom: 0;
                    opacity: 0;
                    transition: opacity .25s;
                }
            }

            &:hover, &.active {

                span {

                    &:after {
                        opacity: 1;
                    }
                }
            }
        }

        &:last-child {
            //margin-top: 1rem;
            margin-bottom: 0;
        }
    }
}

.c-nav-mobile-content-lang {

    &:before {
        content: '';
        width: 25px;
        height: 2px;
        display: block;
        background-color: $white;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;
    }

    a {
        display: inline-block;
        width: inherit !important;
    }

}

// Main

.c-nav-item {
    font-weight: bold;
    text-transform: none;
    position: relative;
    line-height: 1;
    margin-left: .75rem;
    margin-right: .75rem;
    margin-bottom: 16px;
    font-family: $MetaHeadlineOT;

    &:before {
        content: "";
        height: 100%;
        width: 1px;
        position: absolute;
        left: -.75rem;
        top: 0;
        display: block;
        background-color: rgba(255,255,255,.5)
    }

    &:after {
        content:"";
        width: 100%;
        height: 2px;
        position: absolute;
        background-color: $primary;
        left: 0;
        bottom: -8px;
        opacity: 0;
        transition: opacity .25s;
    }

    &:hover {
        text-decoration: none !important;

        &:after {
            opacity: 1;
        }
    }

    @include first {
        &:before {
            display: none;
        }
    }

    @include last {
        margin-right: 0 !important;
    }
}

.c-nav-item-active {

    &:after {
        opacity: 1;
    }
}
