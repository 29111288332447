.c-login {
    background-color: $dark;
    color: $dark;
}

#LoginRegisterLoginForm, #LoginRegisterForm {
    h2 {
        display: none;
    }
}

#LoginRegisterProfileForm {
    h2 {
        text-align: center;
    }
}

#loginRegister {
    p.LoginRegisterMessage {
        text-align: center;
    }
}

ul.LoginRegisterLinks {

    text-align: center;

    li.LoginRegisterLinksRegister, li.LoginRegisterLinksProfile, li.LoginRegisterLinksLogout, li.LoginRegisterLinksForgot {
        list-style-type: none;
        color: $primary;
        font-weight: bold;
    }
}

.Inputfield {
    clear: both;
    box-sizing: border-box;
}

.Inputfield:not(.InputfieldSubmit) {

}

.InputfieldHeader {
    /* primary label */
    font-weight: bold;
    color: $dark;
}

.Inputfield input[type=text],
.Inputfield input[type=email] {
    width: 100%;
}

.InputfieldColumnWidth {
    /* columns that are not 100% width */
    float: left;
    clear: none;
}

.InputfieldColumnWidthFirst {
    /* first column in a row of Inputfield columns */
    clear: both;
}

.InputfieldColumnWidth:not(.InputfieldColumnWidthFirst) {
    /* any column except first, in a row of Inputfield columns */
    margin-left: 1%;
}

.InputfieldPassword input {
    /* password input */
    width: 50%;
}

.LoginRegisterMessage {
    /* message notification */
    color: green;
    border: 1px solid green;
    font-weight: bold;
    padding: 10px 20px;
}

.LoginRegisterError {
    /* error notification */
    color: red;
    border: 1px solid red;
    font-weight: bold;
    padding: 10px 20px;
}


// Password

.InputfieldPasswordRow > label {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.InputfieldPassword input[disabled=disabled] {
    opacity: 0.6;
}

.InputfieldPassword .pass-confirm > span,
.InputfieldPassword .pass-scores > span {
    display: none;
}

.InputfieldPassword .pass-confirm > span.on,
.InputfieldPassword .pass-scores > span.on {
    display: inline;
}

.InputfieldPassword .pass-require-good {
    text-decoration: line-through;
}

.pass-invalid,
.pass-short,
.pass-same,
.pass-common {
    color: red;
}

.confirm-no,
.pass-fail {
    color: orangered;
}

.pass-weak {
    color: orangered;
}

.pass-medium {
    color: orangered;
}

.pass-good {
    color: seagreen;
}

.pass-matches .pass-weak,
.pass-matches .pass-medium,
.pass-matches .pass-good,
.pass-matches .pass-excellent {
    color: green;
}

.confirm-yes,
.pass-excellent {
    color: green;
}
