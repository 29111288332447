/*
 * FONT AWESOME (FA)
 */

.c-icon-fa {
    @extend %fa-icon;
    @extend .fab;
    color: $white;
    transition: color .25s;
    font-size: 1.1rem;
    text-decoration: none;

    &:before {
        padding-bottom: 4px;
    }

    &:hover {
        color: $primary;
        text-decoration: none;
    }
}

.c-icon-large{
    font-size:1.6rem;
}

.c-icon-fa-linkedin {

    &:before {
        content: fa-content($fa-var-linkedin);
    }

    &:hover {
        color: $linkedin;
    }
}

.c-icon-fa-facebook {

    &:before {
        content: fa-content($fa-var-facebook-square);
    }

    &:hover {
        color: $facebook;
    }

}

.c-icon-fa-twitter {

    &:before {
        content: fa-content($fa-var-twitter-square);
    }

    &:hover {
        color: $twitter;
    }
}

.c-icon-fa-instagram {

    &:before {
        content: fa-content($fa-var-instagram);
    }

    &:hover {
        color: $instagram;
    }
}
