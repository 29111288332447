/*
 * HEADER
 */

.c-header {
    width: 100%;
    background-color: $dark;
    color: $white;
    z-index: 2;
}

.c-header-top-right {
    small {
        font-size: $base-font-size-min * 0.95;
        color: $medium;
    }

    a {
        color: $white;
        text-decoration: none;
        font-weight: 100;
    }
}

.c-header-title {
    color: $white;
}

.c-header-mobile {
    position: relative;
}

.c-header-mobile-phone {
    z-index: 1000;
    flex-grow: 1;
    flex-basis: 0;
    font-family: $MetaHeadlineOT-CondBold;
}

.c-header-mobile-logo {
    z-index: 1000;
    width: 35px;
    height: 57px;

    img {
        display: block;
        height: 57px;
    }
}

.c-header-mobile-menu {
    flex-grow: 1;
    flex-basis: 0;
}

.c-header-logo-desktop {

    height: 63px;
    display: block;

    img {
        display: block;
        width: 218px;
        margin-top: -4px;
        height: 79px;
    }
}

.c-header-bottom {
    border-top: 1px solid $medium;
}

.c-header-bottom-right {
    .c-nav-item {
        height: 100%;
        margin-bottom: 0;
        padding: 1.125em 0;
        transition: all 0.2s ease-in-out;
        border-bottom: 3px solid transparent;

        &:before, &:after {
            display: none;
        }

        &:hover {
            border-color: $primary;
        }
    }
    .c-nav-item-active {
        border-color: $primary;
    }
}
