.c-employers-grid, .c-cases-grid {

    figure {
        position: relative;

        &:after {
            content:"";
            position: absolute;
            display: block;
            bottom: 0;
            left: 0;
            width: 0;
            height: 8px;
            background-color: $primary;
            transition: width 300ms ease-in-out;
        }

        &:hover {

            &:after {
                width: 100%;
            }
        }
    }

    figcaption {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 1em;
        background: transparent;
        transition: background-color 300ms ease-in-out;

        h3, p {
            color: $white;
            text-shadow: 1px 1px rgba(0,0,0,.3);
            font-weight: bold;
            line-height: 1;
            text-transform: capitalize;
        }

        p {
            text-transform: uppercase;
        }

        a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 99;
            text-indent: 200%;
            white-space: nowrap;
            font-size: 0;
            opacity: 0;
        }

        &:hover {
            background-color: rgba(0,0,0,.3);
        }

        @include media-query(md) {
            padding: 2em;
        }
    }
}

.c-cases-grid {

    figcaption {

        h3, p {
            opacity: 0;
            transition: opacity 300ms ease-in-out;
        }

        &:hover {

            h3, p {
                opacity: 1;
            }
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    opacity: 1;
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
