/*
 * MAIN
 */

.c-main {
    width: 100%;
    background-color: $white;
    z-index: 1;
}

/* clear fix */
.isotope:after {
    content: '';
    display: block;
    clear: both;
}

.TextformatterVideoEmbed {
    display: block;
    width: 100%;
}
