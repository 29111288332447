/*
 * PROFILE
 */

.c-profile {

    color: $white;
    background-color: $dark;

    h1 {
        line-height: 1;
    }

    h1, h2, h3, h4, p {
        color: $white;
    }

    h3 {
        color: $primary;
    }

    h4 {
        //background-color: $primary;
        text-transform: uppercase;
        display: inline-block;
        //padding-left: .5em;
        //padding-right: .5em;
    }

    p {
        a {
            color: $white;
        }
    }
}

.c-profile-bio {

    border-top: 1px solid rgba(255,255,255,.33);

    h3 {

        color: $white;

        &:after {
            content: "";
            position: absolute;
            display: block;
            background-color: $primary;
            height: 8px;
            width: 32px;
        }
    }
}
