/*
 * SETTINGS
 */

// GRID
$columns: 12;
$gutter: 16/16*1rem;

$breakpoints: (
    xs: 480/16*1rem,
    sm: 640/16*1rem,
    md: 960/16*1rem,
    lg: 1024/16*1rem,
    xl: 1140/16*1rem
);

// SPACING
$spacing: (
    auto: auto,
    none: 0/16*1rem,
    xs: 8/16*1rem,
    sm: 16/16*1rem,
    md: 24/16*1rem,
    lg: 32/16*1rem,
    xl: 40/16*1rem,
    xxl: 56/16*1rem
);

// COLORS
$info: #000000;
$success: #4caf50;
$warning: #ffc107;
$error: #e70638;

$dark: #000000;
$medium: #747474;
$grey: #BFBFBF;
$light: #eeeeee;        // rgba(238, 238, 238, 1)
$white: #ffffff;

$primary: #fd9900;      // rgba(253, 153, 0, 1)
$secondary: #c6037f;    // rgba(198, 3, 127, 1)
$tertiary: #237dc0;     // rgba(35, 125, 192, 1)

// SOCIAL MEDIA
$linkedin: #0077b5;
$facebook: #3b5998;
$twitter: #1da1f2;
$instagram: #c13584;

// BORDER RADIUS
$border-radius: 1px;

// TYPOGRAPHY

@include fontFace('MetaHeadlineOT','../fonts/MetaHeadlineOT/MetaHeadlineOT-CondBold');
@include fontFace('MetaHeadlineOT','../fonts/MetaHeadlineOT/MetaHeadlineOT');
@include fontFace('MetaOT-Norm','../fonts/MetaOT/MetaOT-Norm');

$MetaHeadlineOT: 'MetaHeadlineOT', Helvetica, sans-serif;
$MetaHeadlineOT-CondBold: 'MetaHeadlineOT-CondBold', Helvetica, sans-serif;
$MetaOT: 'MetaOT-Norm', Times, serif;

$min: 0.9;

$base-font-family: $MetaOT;
$base-font-size-min: 1.125rem * $min;
$base-font-size-max: 1.125rem;
$base-font-weight: normal;
$base-line-height: 1.5;
$base-color: $dark;

$h1-font-family: $MetaHeadlineOT-CondBold;
$h1-font-size-min: 3.45rem * $min;
$h1-font-size-max: 3.45rem; //55px
$h1-font-weight: bold;
$h1-line-height: 1.5;
$h1-color: $dark;

$h2-font-family: $MetaHeadlineOT-CondBold;
$h2-font-size-min: 2.75rem * $min;
$h2-font-size-max: 2.75rem; //44px
$h2-font-weight: 500;
$h2-line-height: 1.4;
$h2-color: $dark;

$h3-font-family: $MetaHeadlineOT-CondBold;
$h3-font-size-min: 1.875rem * $min;
$h3-font-size-max: 1.875rem; //30px
$h3-font-weight: 400;
$h3-line-height: 1.5;
$h3-color: $dark;

$h4-font-family: $MetaHeadlineOT-CondBold;
$h4-font-size-min: 1.625rem * $min;
$h4-font-size-max: 1.625rem; //26px
$h4-font-weight: 400;
$h4-line-height: 1.5;
$h4-color: $dark;

$h5-font-family: $MetaHeadlineOT-CondBold;
$h5-font-size-min: 1.25rem * $min;
$h5-font-size-max: 1.25rem; //20px
$h5-font-weight: 400;
$h5-line-height: 1.5;
$h5-color: $dark;

$h6-font-family: $MetaOT;
$h6-font-size-min: 1.15rem * $min;
$h6-font-size-max: 1.15rem; //18px
$h6-font-weight: 400;
$h6-line-height: 1.5;
$h6-color: $dark;
