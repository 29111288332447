/*
 * BUTTONS
 */

.c-button{
    //font-weight: 300;
    //display: block;
    //text-transform: uppercase;
    //text-align: center;

    .icon {
        font-size: .9rem;
        color: $primary;
        transition: all 0.2s ease-in-out;
        position: relative;
        left: 0;
    }

    .icon.icon-arrow-left {
        margin-right: 0.35rem;
    }

    .icon.icon-arrow-right {
        margin-left: 0.35rem;
    }

    &:hover {

        .icon.icon-arrow-left {
            left: -5px;
        }

        .icon.icon-arrow-right {
            left: 5px;
        }
    }
}
